import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  useNavigation,
} from 'react-router-dom';
import Error from './pages/error';
import NotFound from './pages/not-found';
import RestrictedAccess from './components/restricted-access';
import PageLoaded from './components/page-loaded';
import LoaderSuspense from './components/loader-suspense';
import Background from './components/background';

const RootLayout = () => {
  const navigation = useNavigation();

  return (
    <>
      <Background />
      <PageLoaded />
      <Outlet />
      <LoaderSuspense show={navigation.state === 'loading'} />
    </>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />} errorElement={<Error />}>
      <Route path="/*" element={<NotFound />} />
      <Route path="/reset-password" lazy={() => import('./pages/auth/reset-password/page')} />
      <Route path="/sign-in" lazy={() => import('./pages/auth/sign-in/page')} />
      <Route path="/sign-up" lazy={() => import('./pages/auth/sign-up/page')} />
      <Route path="/" lazy={() => import('./pages/catalog/page')} />
      <Route path="/catalog" lazy={() => import('./pages/catalog/page')} />
      <Route element={<RestrictedAccess />}>
        {/* woodlock */}
        {...import.meta.env.VITE_SHORCUTS === 'true'
          ? [
              <Route path="/woodlock/boxes" lazy={() => import('./pages/woodlock/boxes/page')} />,
              <Route lazy={() => import('./pages/woodlock/layout')}>
                <Route
                  index
                  path="/woodlock/:boxId?"
                  lazy={() => import('./pages/woodlock/home/page')}
                />
                <Route
                  path="/woodlock/:boxId/*"
                  lazy={() => import('./pages/woodlock/home/page')}
                />
                <Route
                  path="/woodlock/:boxId/history"
                  lazy={() => import('./pages/woodlock/history/page')}
                />
                <Route
                  path="/woodlock/:boxId/help"
                  lazy={() => import('./pages/woodlock/help/page')}
                />
              </Route>,
            ]
          : []}
        {/* TAROT KILLER */}
        <Route path="/tarot/boxes" lazy={() => import('./pages/tarot-killer/boxes/page')} />
        <Route path="/tarot/" lazy={() => import('./pages/tarot-killer/layout')}>
          <Route path="/tarot/:boxId" lazy={() => import('./pages/tarot-killer/home/page')} />
          <Route path="/tarot/:boxId/*" lazy={() => import('./pages/tarot-killer/home/page')} />
          <Route
            path="/tarot/:boxId/history"
            lazy={() => import('./pages/tarot-killer/history/page')}
          />
          <Route path="/tarot/:boxId/help" lazy={() => import('./pages/tarot-killer/help/page')} />
        </Route>
        {/* CIAO BELLA */}
        {...import.meta.env.VITE_SHORCUTS === 'true'
          ? [
              <Route lazy={() => import('./pages/ciao-bella/layout')}>
                <Route path="/ciao-bella" lazy={() => import('./pages/ciao-bella/requests/page')} />
                <Route
                  path="/ciao-bella/help"
                  lazy={() => import('./pages/ciao-bella/help/page')}
                />
                <Route
                  path="/ciao-bella/history"
                  lazy={() => import('./pages/ciao-bella/history/page')}
                />
              </Route>,
              <Route
                path="/ciao-bella/episodes"
                lazy={() => import('./pages/ciao-bella/episodes/page')}
              />,
            ]
          : []}
        {/* beaurecueil */}
        {...import.meta.env.VITE_SHORCUTS === 'true'
          ? [
              <Route lazy={() => import('./pages/beaurecueil/layout')}>
                <Route path="/beaurecueil" lazy={() => import('./pages/beaurecueil/home/page')} />
                <Route
                  path="/beaurecueil/history"
                  lazy={() => import('./pages/beaurecueil/history/page')}
                />
                <Route
                  path="/beaurecueil/help"
                  lazy={() => import('./pages/beaurecueil/help/page')}
                />
                <Route
                  path="/beaurecueil/explorations/cave"
                  lazy={() => import('./pages/beaurecueil/explorations/cave/page')}
                />
                <Route
                  path="/beaurecueil/explorations/cottages"
                  lazy={() => import('./pages/beaurecueil/explorations/cottages/page')}
                />
                <Route
                  path="/beaurecueil/explorations/passaour"
                  lazy={() => import('./pages/beaurecueil/explorations/passaour/page')}
                />
                <Route
                  path="/beaurecueil/polygraph"
                  lazy={() => import('./pages/beaurecueil/polygraph/page')}
                />
              </Route>,
            ]
          : []}
        {/* illusion */}
        {...import.meta.env.VITE_SHORCUTS === 'true'
          ? [
              <Route lazy={() => import('./pages/illusion/layout')}>
                <Route index path="/illusion" lazy={() => import('./pages/illusion/home/page')} />
                <Route
                  path="/illusion/history"
                  lazy={() => import('./pages/illusion/history/page')}
                />
                <Route path="/illusion/help" lazy={() => import('./pages/illusion/help/page')} />
                <Route
                  path="/illusion/cipher"
                  lazy={() => import('./pages/illusion/cipher/page')}
                />
              </Route>,
            ]
          : []}

        <Route path="/credits" lazy={() => import('./pages/credits/page')} />
        <Route path="/legales" lazy={() => import('./pages/legal/page')} />
        <Route path="/settings" lazy={() => import('./pages/settings/page')} />
      </Route>
    </Route>
  )
);

const Router = () => <RouterProvider router={router} />;

export default Router;
